// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application"

import HelloController from "./hello_controller"
import CalculatorController from "./calculator_controller"
import TrixToolbarController from "./trix_toolbar_controller";

application.register("hello", HelloController)
application.register("calculator", CalculatorController)
application.register("trix-toolbar", TrixToolbarController )


import VoiceRecorderController from "./voice_recorder_controller"
application.register("voice-recorder", VoiceRecorderController)

// Eager load all controllers defined in the import map under controllers/**/*_controller
//import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
//eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)
