import { Controller } from "@hotwired/stimulus"

import * as React from 'react'
import * as ReactDOM from "react-dom";
import Resistance from "./../components/Resistance"
import Velocity from "./../components/Velocity"

export default class extends Controller {
  static values = { name: { type: String, default: 'resistance' } }

  connect() {
    switch (this.nameValue) {
      case 'resistance':
        ReactDOM.render(<Resistance />, this.element);
        break;
      case 'velocity':
        ReactDOM.render(<Velocity />, this.element);
        break;
    }
  }
}
