import React from 'react';
import { useState, useEffect } from 'react';

export default function Velocity(props) {
  const [speed, setSpeed] = useState(0);
  const [distance, setDistance] = useState('')
  const [time, setTime] = useState('')

  useEffect(() => {
    const calculateSpeed = () => {
      const s = parseFloat(distance);
      const t = parseFloat(time);

      if (!isNaN(s) && !isNaN(t) && t !== 0) {
        const calculatedVelocity = s / t;
        setSpeed(calculatedVelocity.toFixed(2));
      }
    };

    calculateSpeed(speed);
  }, [distance, time]);


  function handleDistanceChange(e) {
    const distance = parseFloat(e.target.value);
    if (isNaN(distance)) { return; }
    setDistance(distance);
  }

  function handleTimeChange(e) {
    const time = parseFloat(e.target.value);
    if (time <= 0 || isNaN(time)) return;
    setTime(time);
  }

  return (
  <div className={"mt-6 max-w-sm"}>
    <h4 className="text-lg font-bold text-gray-800 dark:text-white">Speed calculator </h4>
    <div className="space-y-2 sm:space-y-0 sm:space-x-3">
      <div className={"grid three-column mt-4 align-middle items-center gap-x-2 gap-y-4 align-middle "} >
        <div className={"mr-4 inline-flex"}><label htmlFor="calc-distance-input"
                     className="block text-sm font-medium  dark:text-white">Distance</label></div>
        <div><input type="number" id="calc-distance-input"
                    className="py-3 px-4 block w-40 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                    value={distance}
                    onChange={handleDistanceChange}
                    aria-describedby="calc-distance-input"/></div>
        <div><p className="text-sm text-gray-500 mt-2" >m</p></div>


        <div><label htmlFor="calc-time-input"
                    className="block text-sm font-medium mb-2 dark:text-white">Time</label> </div>
        <div>
          <input type="number" id="calc-time-input"
                 className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                 value={time}
                 onChange={handleTimeChange}
                 aria-describedby="hs-inline-input-helper-text"/>
        </div>
        <div>
          <p className="text-sm text-gray-500 mt-2" id="hs-inline-input-helper-text">s</p>
        </div>

        <div> <label htmlFor="inline-input-label-with-helper-text"
                     className="block text-sm font-medium mb-2 dark:text-white">Speed</label></div>
        <div>
          <input type="email" id="inline-input-label-with-helper-text"
                 className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                 disabled={true}
                 value={speed}
                 aria-describedby="hs-inline-input-helper-text"/>
        </div>
        <div>
          <p className="text-sm text-gray-500 mt-2" id="hs-inline-input-helper-text">m/s</p>
        </div>
      </div>
    </div>
  </div>
  )
}