import {Controller} from "@hotwired/stimulus"

export default class TrixToolbarController extends Controller {

  static generateH2Button() {
    let h2 = document.createElement("button");
    h2.setAttribute("type", "button");
    h2.setAttribute("data-trix-attribute", "h2");
    h2.setAttribute("tabindex", -1);
    h2.setAttribute("title", "h2");
    h2.classList.add("trix-button", "trix-button--icon-h2");
    h2.innerHTML = "H2";
    return h2
  }

  static generateH3Button() {
    let h3 = document.createElement("button");
    h3.setAttribute("type", "button");
    h3.setAttribute("data-trix-attribute", "h3");
    h3.setAttribute("tabindex", -1);
    h3.setAttribute("title", "h3");
    h3.classList.add("trix-button", "trix-button--icon-h3");
    h3.innerHTML = "H3";

    return h3
  }

  connect() {


    addEventListener("trix-initialize", function (event) {

      let h2 = TrixToolbarController.generateH2Button();
      let h3 = TrixToolbarController.generateH3Button();

      // add button to toolbar - inside the text tools group
      document.querySelector(".trix-button-group--text-tools").appendChild(h2);
      document.querySelector(".trix-button-group--text-tools").appendChild(h3);


      Trix.config.blockAttributes.h2 = {
        tagName: "h2",
        terminal: true,
        breakOnReturn: true,
        group: false
      }

      Trix.config.blockAttributes.h3 = {
        tagName: "h3",
        terminal: true,
        breakOnReturn: true,
        group: false
      }

    }, true)
  }
}
