import React from 'react';
import { useState, useEffect } from 'react';

export default function Resistance(props) {
  const [resistance, setResistance] = useState(0);
  const [current, setCurrent] = useState(1)
  const [voltage, setVoltage] = useState(0)

  useEffect(() => {
    const resistance = (voltage / current).toFixed(2);
    setResistance(resistance);
  }, [voltage, current]);


  function handleVoltageChange(e) {
    const volt = parseFloat(e.target.value);
    if (isNaN(volt)) { return; }
    setVoltage(volt);
  }

  function handleCurrentChange(e) {
    const current = parseFloat(e.target.value);
    if (current <= 0 || isNaN(current)) return;
    setCurrent(current);
  }

  return (
  <div className={"flex flex-col gap-4 mt-6 max-w-sm"}>
    <h4 className="text-lg font-bold text-gray-800 dark:text-white"> Resistance calculator </h4>
    <div className="sm:inline-flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-3">
      <label htmlFor="inline-input-label-with-helper-text"
             className="block text-sm font-medium mb-2 dark:text-white">Voltage</label>
      <input type="email" id="inline-input-label-with-helper-text"
             className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
             value={voltage}
             onChange={handleVoltageChange}
             aria-describedby="hs-inline-input-helper-text"/>
      <p className="text-sm text-gray-500 mt-2" id="hs-inline-input-helper-text">V</p>
    </div>
    <div className="sm:inline-flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-3">
      <label htmlFor="inline-input-label-with-helper-text"
             className="block text-sm font-medium mb-2 dark:text-white">Current</label>
      <input type="email" id="inline-input-label-with-helper-text"
             className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
             value={current}
             onChange={handleCurrentChange}
             aria-describedby="hs-inline-input-helper-text"/>
      <p className="text-sm text-gray-500 mt-2" id="hs-inline-input-helper-text">A</p>
    </div>
    <div className="sm:inline-flex sm:items-center space-y-2 sm:space-y-0 sm:space-x-3">
      <label htmlFor="inline-input-label-with-helper-text"
             className="block text-sm font-medium mb-2 dark:text-white">Resistance</label>
      <input type="email" id="inline-input-label-with-helper-text"
             className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
             value={resistance}
             aria-describedby="hs-inline-input-helper-text"/>
      <p className="text-sm text-gray-500 mt-2" id="hs-inline-input-helper-text">Ohm</p>
    </div>
  </div>
  )
}