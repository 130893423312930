import { Controller } from "@hotwired/stimulus"

import * as React from 'react'
import * as ReactDOM from "react-dom";
import DiffText from "./../components/DiffText"

export default class extends Controller {
  static values = { text1: { type: String, default: 'marek' }, text2: { type: String, default: 'darek' }  }

  connect() {
    console.log('connected');
    ReactDOM.render(<DiffText oldValue={ this.text1Value } newValue={ this.text2Value } />, this.element);
  }
}
