import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = { record: Number }

    initialize() {
      console.log(this.recordValue)
      console.log(typeof this.recordValue)
    }

    record() {
      this.mediaRecorder.start();
      console.log('Start recording...');
    }

    stop() {
      this.mediaRecorder.stop();
      console.log('Stop recording.');
    }

    connect() {
        const controller = this;
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(function(stream) {
              controller.mediaRecorder = new MediaRecorder(stream);
              const chunks = [];

              controller.mediaRecorder.ondataavailable = function(e) {
                  if (e.data.size > 0) {
                      chunks.push(e.data);
                  }
              };

              controller.mediaRecorder.onstop = function() {
                  const audioBlob = new Blob(chunks, { type: 'audio/wav' });
                  const formData = new FormData();
                  formData.append('audio_recording[audio]', audioBlob);

                  const id  = controller.recordValue;
                  console.log(id);

                  fetch(`/admin/audio_recordings/${id}/record`, {
                      method: 'POST',
                      body: formData
                  })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Audio uploaded to AWS S3:', data.s3_url);
                    });
              };
          });
    }

    disconnect() {
      console.log("goodbye world");
    }
}